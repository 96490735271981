import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import SkullGuy from "../components/skull"
import Login from "../components/login"
import SkullLoad from "../components/skullLoad"

const LoginPage = () => (
  <Layout>
    <SEO title="Login" />
    <SkullGuy style={{ position: `absolute`, zIndex: '-1', left: '0px', top: '0px' }} />
    <div style={{display: `flex`, justifyContent: `center`}}>
      <SkullLoad maxwidth="250px"/>
    </div>
    <Login />
  </Layout>
)

export default LoginPage
